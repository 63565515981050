<template>
  <b-card
    class="border-success mb-1 overflow-auto"
    body-class="d-flex flex-column flex-lg-row p-50"
  >
    <div style="min-width: 200px">
      <div class="d-flex-between mb-50 mb-lg-0">
        <span class="fw-700"> {{ $t('train.search.result.passenger') }} {{ indexTicket + 1 }}</span>

        <div class="d-flex-between d-lg-none">
          <div class="d-flex-between mr-1">
            <IAmIcon
              icon="stopWatch"
              size="20"
              :color="timer > 1 ? '' : '#EF5350'"
            />
            <span
              v-if="timer > 1"
              class="px-25"
            >
              {{ timer }}
            </span>
          </div>

          <b-button
            variant="danger"
            class="btn-icon p-50 rounded-lg"
            @click="deleteTicketHandle(itineraryIndex, ticket)"
          >
            <IAmIcon icon="trash" />
          </b-button>
        </div>
      </div>

      <div class="mb-50">
        <v-select
          :value="ticket.passengerTargetCode"
          label="name"
          append-to-body
          :clearable="false"
          :searchable="false"
          :reduce="v => v.code"
          :options="getListPassengerTarget"
          @input="value => this.$emit('changePaxTypeCode', {ticketId: ticket.ticketId, value})"
        >
          <template #option="data">
            <span>
              {{ ['QT_NuocNgoai'].includes(data.group) ? `${data.name} nước ngoài` : data.name }}
            </span>
          </template>

          <template #selected-option="data">
            <span>
              {{ [6 , 7].includes(data?.code) ? `${data.name} nước ngoài` : data.name }}
            </span>
          </template>

          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </div>

      <!-- ANCHOR - Tên hành khách -->
      <div class="mb-50">
        <validation-provider
          #default="{ errors }"
          :name="$t('train.search.result.passengerName')"
          :rules="`required`"
          :vid="`fullName_${ticket.ticketId}`"
        >
          <b-form-input
            :id="`fullName_${ticket.ticketId}`"
            v-blur-with-emit.fullName="value => handleBlurWithEmit('fullName', formatterNameOnly(value))"
            :value="ticket.fullName"
            lazy-formatter
            :state="errors[0] ? false : null"
            :formatter="formatterNameOnly"
            :placeholder="$t('train.search.result.passengerName')"
          />
          <b-tooltip
            v-if="errors[0]"
            :target="`fullName_${ticket.ticketId}`"
            triggers="hover focus"
            placement="top"
            boundary="viewport"
            variant="danger"
            :title="errors[0]"
          >
            <span class="text-white py-25 mb-0">
              {{ errors[0] }}
            </span>
          </b-tooltip>
        </validation-provider>
      </div>

      <!-- ANCHOR - CCCD / Hộ chiếu -->
      <div class="mb-50">
        <flat-pickr
          v-if="[2,7].includes(ticket.passengerTargetCode)"
          v-model="ticket.identifyNumber"
          class="form-control form-control-md bg-light-secondary"
          disabled
          :config="{
            dateFormat: 'Y-m-d',
            altFormat: 'd-m-Y',
            class: 'form-control-md bg-light-secondary',
            altInput: true,
            disableMobile: true,
          }"
        />
        <template v-else>
          <validation-provider
            #default="{ errors }"
            :name="$t('train.search.result.idPassportLicense')"
            :rules="`required`"
            :vid="`identifyNumber_${ticket.ticketId}`"
          >
            <b-form-input
              :id="`identifyNumber_${ticket.ticketId}`"
              v-blur-with-emit.identifyNumber="value => handleBlurWithEmit('identifyNumber', formatterPassportOnly(value))"
              :value="ticket.identifyNumber"
              :state="errors[0] ? false : null"
              lazy-formatter
              :formatter="formatterPassportOnly"
              :placeholder="$t('train.search.result.idPassportLicense')"
            />
            <b-tooltip
              v-if="errors[0]"
              :target="`identifyNumber_${ticket.ticketId}`"
              triggers="hover focus"
              placement="top"
              boundary="viewport"
              variant="danger"
              :title="errors[0]"
            >
              <span class="text-white py-25 mb-0">
                {{ errors[0] }}
              </span>
            </b-tooltip>
          </validation-provider>
        </template>
      </div>
    </div>

    <b-table-lite
      bordered
      responsive
      small
      class="rounded ml-lg-50 mb-0"
      thead-class="text-body fw-600 text-nowrap text-center"
      :items="[ticket]"
      :fields="tablePaxPriceColumns"
    >
      <template
        v-for="column in tablePaxPriceColumns"
        #[`head(${column})`]="data"
      >
        <span
          :key="column"
          class="text-body"
        >
          {{ $t(`train.search.result.columns.${data.column}`) }}
        </span>
      </template>

      <template #cell(seatInfo)="{ item }">
        <div
          class="text-nowrap fw-700"
          style="min-width: 155px"
        >
          {{ item.trainCode }} {{ `${getAirportName(item.departure)}-${getAirportName(item.arrival)}` }}
        </div>
        <div class="text-body">
          <div class="text-nowrap">
            Khởi hành: <span class="fw-600">{{ convertISODateTimeLangVN(item.departureTime).shortDay }}, {{ convertISODateTime(item.departureTime).time }}</span>
          </div>
          <div class="text-nowrap">
            Đến: <span class="fw-600">{{ convertISODateTimeLangVN(item.arrivalTime).shortDay }} | {{ convertISODateTime(item.arrivalTime).time }}</span>
          </div>
        </div>
        <div class="text-nowrap">
          {{ $t('train.search.result.cart.coach') }} <span class="fw-700">{{ item.coachName }}</span> {{ $t('train.search.result.cart.seat') }} <span class="fw-700">{{ item.seatName }}</span>
        </div>
        <div :class="`${['GP'].includes(item.seatTypeCode)}`">
          {{ resolveNameByListSeatType(item.seatTypeCode) }}
        </div>
        <div
          v-if="item.refTicketId"
          :class="`text-info fw-700
            ${isMobileView ? 'font-small-4' : 'font-medium-2'}
          `"
        >
          {{ item.isReturn ? $t('train.search.result.roundTripInbound') : $t('train.search.result.roundTripOutbound') }}
        </div>
        <div
          v-else
          :class="`text-warning fw-700
            ${isMobileView ? 'font-small-4' : 'font-medium-2'}
          `"
        >
          {{ item.isReturn ? $t('train.search.result.oneWayInbound') : $t('train.search.result.oneWayOutbound') }}
        </div>
      </template>

      <template #cell(ticketPrice)="{ item }">
        <!-- <div
          v-b-tooltip.hover.html.window.v-primary
          class="d-flex-between mb-25"
          :title="`${$t('train.search.result.ticketPrice')} ${resolvePriceTicket(item).priceWithInsurance}<br>
                        ${$t('train.search.result.serviceFee')} ${resolvePriceTicket(item).serviceFee}<br>
                        `"
        >
          <div class="text-nowrap mr-50">
            {{ $t('train.search.result.ticketPrice') }}
          </div>
          <div class="text-right fw-600">
            {{ resolvePriceTicket(item).resultPrice }}
          </div>
        </div>

        <div class="d-flex-between">
          <div class="text-nowrap mr-50">
            {{ $t('train.search.result.discountEligible') }}
          </div>
          <div class="text-right fw-600 text-warning">
            {{
              item.applyPromoData
                ? formatCurrency(item.applyPromoData.calcPromotion.discountPassenger)
                : formatCurrency(resolveDiscountPriceByPassengerCode(item.ticketPrice, item.passengerTargetCode))
            }}
          </div>
        </div> -->

        <div class="d-flex-between mb-25">
          <div class="text-nowrap mr-50">
            {{ $t('train.search.result.columns.originalPrice') }}:
          </div>
          <div class="text-right fw-600">
            {{ resolvePriceTicket(item).resultPrice }}
          </div>
        </div>
        <div class="d-flex-between mb-25">
          <div class="text-nowrap mr-50">
            {{ $t('train.search.result.columns.servicesPrice') }}:
          </div>
          <div class="text-right fw-600">
            {{ resolvePriceTicket(item).serviceFee }}
          </div>
        </div>
      </template>

      <template #cell(discountPromotion)="{ item }">
        <!-- <div
          v-if="!item.applyPromoData"
          class="text-right"
        >
          0
        </div>
        <div
          v-else
          style="min-width: 170px"
        >
          <div class="fw-600">
            {{ item.applyPromoData.promotionName }} (giảm <span class="fw-800">{{ formatCurrency(item.applyPromoData.calcPromotion.commission) }}</span>)
          </div>
          <small class="text-danger font-italic">
            * {{ $t('train.search.result.promoNote') }}
          </small>
        </div> -->
        <div class="d-flex-between mb-25">
          <div class="text-nowrap mr-50">
            {{ $t('train.search.result.columns.discountPassenger') }}:
          </div>
          <div class="text-right fw-600">
            {{
              item.applyPromoData
                ? formatCurrency(item.applyPromoData.calcPromotion.discountPassenger)
                : formatCurrency(resolveDiscountPriceByPassengerCode(item.ticketPrice, item.passengerTargetCode))
            }}
          </div>
        </div>
        <div class="d-flex-between mb-25">
          <div class="text-nowrap mr-50">
            {{ $t('train.search.result.columns.promotionalDiscounts') }}:
          </div>
          <div class="text-right fw-600">
            {{
              item?.applyPromoData?.calcPromotion?.commission
                ? formatCurrency(item.applyPromoData.calcPromotion.commission)
                : 0
            }}
          </div>
        </div>
      </template>

      <template #cell(totalPrice)="{ item }">
        <div class="d-flex align-items-center justify-content-end mr-75">
          <span
            :class="`fw-700
            ${item.isExpired ? 'text-danger' : 'text-info'}
          `"
          >
            {{ item.isExpired ? 'Hết thời gian giữ vé!' : resolveTotalPriceTicket(item) }}
          </span>

          <feather-icon
            v-b-tooltip.hover.html.window.v-primary
            icon="AlertCircleIcon"
            size="22"
            class="mx-50 text-info cursor-pointer"
            :title="item?.applyPromoData?.promotionName && item?.applyPromoData?.calcPromotion?.commission
              ? `${item.applyPromoData.promotionName}<br>
              ${`Giảm: ${formatCurrency(item.applyPromoData.calcPromotion.commission)}`} <br>`
              : 'Không có giảm giá khuyến mãi'"
          />
        </div>
      </template>
    </b-table-lite>

    <div class="btn_delete_class d-none d-lg-flex align-items-center">
      <div class="d-flex-between mr-1">
        <IAmIcon
          icon="stopWatch"
          size="20"
          :color="timer > 1 ? '' : '#EF5350'"
        />
        <span
          v-if="timer > 1"
          class="px-25"
        >
          {{ timer }}
        </span>
      </div>

      <b-button
        variant="danger"
        class="btn-icon p-50"
        @click="deleteTicketHandle(itineraryIndex, ticket)"
      >
        <IAmIcon icon="trash" />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  BCard, BButton, BTableLite, BTooltip, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { useInterval } from '@vueuse/core'

import store from '@/store'

import {
  convertISODateTimeLangVN,
  convertISODateTime,
  formatCurrency,
} from '@core/utils/filter'
import { formatterNameOnly, removeAccentsUpperCaseFormatterNotTrim, formatterPassportOnly } from '@core/comp-functions/forms/formatter-input'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BButton,
    BTableLite,
    BTooltip,
    BFormInput,

    vSelect,
    ValidationProvider,

    flatPickr: () => import('vue-flatpickr-component'),
  },
  directives: {
    // NOTE: Dùng blur để emit update data vào store, @blur của BFormImput ko lấy được value
    // NOTE: Data từ vFor khi truyền vào handle không reactive.

    blurWithEmit: {
      bind(el, binding) {
        el.prevValue = el.value || ''
        el.blurHandler = event => {
          let newValue = ''
          if (binding.modifiers?.fullName) {
            newValue = formatterNameOnly(event.target.value)
          } else if (binding.modifiers?.identifyNumber) {
            newValue = formatterPassportOnly(event.target.value)
          }
          if (newValue !== el.prevValue) {
            binding.value(newValue)
            el.prevValue = newValue
          }
        }
        el.addEventListener('blur', el.blurHandler)
      },
      unbind(el) {
        if (el.blurHandler) {
          el.removeEventListener('blur', el.blurHandler)
          delete el.blurHandler
        }
      },
    },
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    indexTicket: {
      type: Number,
      required: true,
    },
    itineraryIndex: {
      type: Number,
      required: true,
    },
    tablePaxPriceColumns: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      getListPassengerTarget,
      resolveNameByListSeatType,
      deleteTicketHandle,
      getAirportName,
      resolveDiscountPriceByPassengerCode,
      resolveTotalPriceTicket,

      getServiceFeeByPassengerTargetCode,
    } = useTrainHandle()

    const totalDuration = props.ticket.duration
    const timer = ref(totalDuration)

    const {
      counter, pause,
      // resume, isActive, start, stop,
    } = useInterval(1000)

    watch(counter, nVal => {
      if (nVal < totalDuration) {
        timer.value -= 1
      } else {
        pause() // FIXME: thêm thông báo khi quá giờ giữ
        // deleteTicketHandle(props.itineraryIndex, props.ticket, false)
        // setExpiredTicket(props.itineraryIndex, props.ticket)

        store.commit('app-train/SET_EXPIRED_TICKET', { storeIndex: props.itineraryIndex, ticket: props.ticket })
      }
    })

    function handleBlurWithEmit(field, value) {
      emit('updateDataPax', { field, ticketId: props.ticket.ticketId, value })
    }

    function resolvePriceTicket(ticket) {
      const basePrice = ticket.applyPromoData ? (ticket.applyPromoData?.calcPromotion?.basePrice || 0) : (ticket.ticketPrice || 0)
      const insurancePrice = ticket.applyPromoData ? (ticket.applyPromoData?.calcPromotion?.insurancePrice || 0) : (ticket.insurancePrice || 0)
      const serviceFee = getServiceFeeByPassengerTargetCode(ticket.passengerTargetCode)
      const resultPrice = basePrice + insurancePrice + serviceFee
      return {
        basePrice: formatCurrency(basePrice),
        serviceFee: formatCurrency(serviceFee),
        insurancePrice: formatCurrency(insurancePrice),
        priceWithInsurance: formatCurrency(basePrice + insurancePrice),
        resultPrice: formatCurrency(resultPrice),
      }
    }

    return {
      getListPassengerTarget,
      timer,

      formatCurrency,
      convertISODateTime,
      convertISODateTimeLangVN,
      formatterNameOnly,
      formatterPassportOnly,
      removeAccentsUpperCaseFormatterNotTrim,

      deleteTicketHandle,
      handleBlurWithEmit,
      getAirportName,
      resolveTotalPriceTicket,
      resolveNameByListSeatType,
      resolveDiscountPriceByPassengerCode,
      resolvePriceTicket,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.btn_delete_class {
  position: absolute;
  bottom: 8px;
  right: 8px;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

}
</style>
