var render = function () {
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "border-success mb-1 overflow-auto",
    attrs: {
      "body-class": "d-flex flex-column flex-lg-row p-50"
    }
  }, [_c('div', {
    staticStyle: {
      "min-width": "200px"
    }
  }, [_c('div', {
    staticClass: "d-flex-between mb-50 mb-lg-0"
  }, [_c('span', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.passenger')) + " " + _vm._s(_vm.indexTicket + 1))]), _c('div', {
    staticClass: "d-flex-between d-lg-none"
  }, [_c('div', {
    staticClass: "d-flex-between mr-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "stopWatch",
      "size": "20",
      "color": _vm.timer > 1 ? '' : '#EF5350'
    }
  }), _vm.timer > 1 ? _c('span', {
    staticClass: "px-25"
  }, [_vm._v(" " + _vm._s(_vm.timer) + " ")]) : _vm._e()], 1), _c('b-button', {
    staticClass: "btn-icon p-50 rounded-lg",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteTicketHandle(_vm.itineraryIndex, _vm.ticket);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "trash"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "mb-50"
  }, [_c('v-select', {
    attrs: {
      "value": _vm.ticket.passengerTargetCode,
      "label": "name",
      "append-to-body": "",
      "clearable": false,
      "searchable": false,
      "reduce": function reduce(v) {
        return v.code;
      },
      "options": _vm.getListPassengerTarget
    },
    on: {
      "input": function input(value) {
        return _this.$emit('changePaxTypeCode', {
          ticketId: _vm.ticket.ticketId,
          value: value
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(['QT_NuocNgoai'].includes(data.group) ? "".concat(data.name, " n\u01B0\u1EDBc ngo\xE0i") : data.name) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s([6, 7].includes(data === null || data === void 0 ? void 0 : data.code) ? "".concat(data.name, " n\u01B0\u1EDBc ngo\xE0i") : data.name) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "mb-50"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('train.search.result.passengerName'),
      "rules": "required",
      "vid": "fullName_".concat(_vm.ticket.ticketId)
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "blur-with-emit",
            rawName: "v-blur-with-emit.fullName",
            value: function value(_value) {
              return _vm.handleBlurWithEmit('fullName', _vm.formatterNameOnly(_value));
            },
            expression: "value => handleBlurWithEmit('fullName', formatterNameOnly(value))",
            modifiers: {
              "fullName": true
            }
          }],
          attrs: {
            "id": "fullName_".concat(_vm.ticket.ticketId),
            "value": _vm.ticket.fullName,
            "lazy-formatter": "",
            "state": errors[0] ? false : null,
            "formatter": _vm.formatterNameOnly,
            "placeholder": _vm.$t('train.search.result.passengerName')
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "target": "fullName_".concat(_vm.ticket.ticketId),
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "danger",
            "title": errors[0]
          }
        }, [_c('span', {
          staticClass: "text-white py-25 mb-0"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])]) : _vm._e()];
      }
    }])
  })], 1), _c('div', {
    staticClass: "mb-50"
  }, [[2, 7].includes(_vm.ticket.passengerTargetCode) ? _c('flat-pickr', {
    staticClass: "form-control form-control-md bg-light-secondary",
    attrs: {
      "disabled": "",
      "config": {
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        class: 'form-control-md bg-light-secondary',
        altInput: true,
        disableMobile: true
      }
    },
    model: {
      value: _vm.ticket.identifyNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "identifyNumber", $$v);
      },
      expression: "ticket.identifyNumber"
    }
  }) : [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('train.search.result.idPassportLicense'),
      "rules": "required",
      "vid": "identifyNumber_".concat(_vm.ticket.ticketId)
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "blur-with-emit",
            rawName: "v-blur-with-emit.identifyNumber",
            value: function value(_value2) {
              return _vm.handleBlurWithEmit('identifyNumber', _vm.formatterPassportOnly(_value2));
            },
            expression: "value => handleBlurWithEmit('identifyNumber', formatterPassportOnly(value))",
            modifiers: {
              "identifyNumber": true
            }
          }],
          attrs: {
            "id": "identifyNumber_".concat(_vm.ticket.ticketId),
            "value": _vm.ticket.identifyNumber,
            "state": errors[0] ? false : null,
            "lazy-formatter": "",
            "formatter": _vm.formatterPassportOnly,
            "placeholder": _vm.$t('train.search.result.idPassportLicense')
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "target": "identifyNumber_".concat(_vm.ticket.ticketId),
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "danger",
            "title": errors[0]
          }
        }, [_c('span', {
          staticClass: "text-white py-25 mb-0"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])]) : _vm._e()];
      }
    }])
  })]], 2)]), _c('b-table-lite', {
    staticClass: "rounded ml-lg-50 mb-0",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "thead-class": "text-body fw-600 text-nowrap text-center",
      "items": [_vm.ticket],
      "fields": _vm.tablePaxPriceColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.tablePaxPriceColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-body"
          }, [_vm._v(" " + _vm._s(_vm.$t("train.search.result.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(seatInfo)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-nowrap fw-700",
          staticStyle: {
            "min-width": "155px"
          }
        }, [_vm._v(" " + _vm._s(item.trainCode) + " " + _vm._s("".concat(_vm.getAirportName(item.departure), "-").concat(_vm.getAirportName(item.arrival))) + " ")]), _c('div', {
          staticClass: "text-body"
        }, [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Khởi hành: "), _c('span', {
          staticClass: "fw-600"
        }, [_vm._v(_vm._s(_vm.convertISODateTimeLangVN(item.departureTime).shortDay) + ", " + _vm._s(_vm.convertISODateTime(item.departureTime).time))])]), _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Đến: "), _c('span', {
          staticClass: "fw-600"
        }, [_vm._v(_vm._s(_vm.convertISODateTimeLangVN(item.arrivalTime).shortDay) + " | " + _vm._s(_vm.convertISODateTime(item.arrivalTime).time))])])]), _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.cart.coach')) + " "), _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(item.coachName))]), _vm._v(" " + _vm._s(_vm.$t('train.search.result.cart.seat')) + " "), _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(item.seatName))])]), _c('div', {
          class: "".concat(['GP'].includes(item.seatTypeCode))
        }, [_vm._v(" " + _vm._s(_vm.resolveNameByListSeatType(item.seatTypeCode)) + " ")]), item.refTicketId ? _c('div', {
          class: "text-info fw-700\n          ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', "\n        ")
        }, [_vm._v(" " + _vm._s(item.isReturn ? _vm.$t('train.search.result.roundTripInbound') : _vm.$t('train.search.result.roundTripOutbound')) + " ")]) : _c('div', {
          class: "text-warning fw-700\n          ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', "\n        ")
        }, [_vm._v(" " + _vm._s(item.isReturn ? _vm.$t('train.search.result.oneWayInbound') : _vm.$t('train.search.result.oneWayOutbound')) + " ")])];
      }
    }, {
      key: "cell(ticketPrice)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex-between mb-25"
        }, [_c('div', {
          staticClass: "text-nowrap mr-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.columns.originalPrice')) + ": ")]), _c('div', {
          staticClass: "text-right fw-600"
        }, [_vm._v(" " + _vm._s(_vm.resolvePriceTicket(item).resultPrice) + " ")])]), _c('div', {
          staticClass: "d-flex-between mb-25"
        }, [_c('div', {
          staticClass: "text-nowrap mr-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.columns.servicesPrice')) + ": ")]), _c('div', {
          staticClass: "text-right fw-600"
        }, [_vm._v(" " + _vm._s(_vm.resolvePriceTicket(item).serviceFee) + " ")])])];
      }
    }, {
      key: "cell(discountPromotion)",
      fn: function fn(_ref5) {
        var _item$applyPromoData, _item$applyPromoData$;
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex-between mb-25"
        }, [_c('div', {
          staticClass: "text-nowrap mr-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.columns.discountPassenger')) + ": ")]), _c('div', {
          staticClass: "text-right fw-600"
        }, [_vm._v(" " + _vm._s(item.applyPromoData ? _vm.formatCurrency(item.applyPromoData.calcPromotion.discountPassenger) : _vm.formatCurrency(_vm.resolveDiscountPriceByPassengerCode(item.ticketPrice, item.passengerTargetCode))) + " ")])]), _c('div', {
          staticClass: "d-flex-between mb-25"
        }, [_c('div', {
          staticClass: "text-nowrap mr-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.columns.promotionalDiscounts')) + ": ")]), _c('div', {
          staticClass: "text-right fw-600"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && (_item$applyPromoData = item.applyPromoData) !== null && _item$applyPromoData !== void 0 && (_item$applyPromoData$ = _item$applyPromoData.calcPromotion) !== null && _item$applyPromoData$ !== void 0 && _item$applyPromoData$.commission ? _vm.formatCurrency(item.applyPromoData.calcPromotion.commission) : 0) + " ")])])];
      }
    }, {
      key: "cell(totalPrice)",
      fn: function fn(_ref6) {
        var _item$applyPromoData2, _item$applyPromoData3, _item$applyPromoData4;
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-end mr-75"
        }, [_c('span', {
          class: "fw-700\n          ".concat(item.isExpired ? 'text-danger' : 'text-info', "\n        ")
        }, [_vm._v(" " + _vm._s(item.isExpired ? 'Hết thời gian giữ vé!' : _vm.resolveTotalPriceTicket(item)) + " ")]), _c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.html.window.v-primary",
            modifiers: {
              "hover": true,
              "html": true,
              "window": true,
              "v-primary": true
            }
          }],
          staticClass: "mx-50 text-info cursor-pointer",
          attrs: {
            "icon": "AlertCircleIcon",
            "size": "22",
            "title": item !== null && item !== void 0 && (_item$applyPromoData2 = item.applyPromoData) !== null && _item$applyPromoData2 !== void 0 && _item$applyPromoData2.promotionName && item !== null && item !== void 0 && (_item$applyPromoData3 = item.applyPromoData) !== null && _item$applyPromoData3 !== void 0 && (_item$applyPromoData4 = _item$applyPromoData3.calcPromotion) !== null && _item$applyPromoData4 !== void 0 && _item$applyPromoData4.commission ? "".concat(item.applyPromoData.promotionName, "<br>\n            ", "Gi\u1EA3m: ".concat(_vm.formatCurrency(item.applyPromoData.calcPromotion.commission)), " <br>") : 'Không có giảm giá khuyến mãi'
          }
        })], 1)];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "btn_delete_class d-none d-lg-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex-between mr-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "stopWatch",
      "size": "20",
      "color": _vm.timer > 1 ? '' : '#EF5350'
    }
  }), _vm.timer > 1 ? _c('span', {
    staticClass: "px-25"
  }, [_vm._v(" " + _vm._s(_vm.timer) + " ")]) : _vm._e()], 1), _c('b-button', {
    staticClass: "btn-icon p-50",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteTicketHandle(_vm.itineraryIndex, _vm.ticket);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "trash"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }